// Overrides for Breadcrumbs
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  @include font-size($breadcrumb-font-size);
  list-style: none;
  background-color: $breadcrumb-bg;
  @include border-radius($breadcrumb-border-radius);
  a {
    padding: 1px 0;
    font-weight: 500;
    color: $breadcrumb-color;
    &:hover {
      color: $midnight;
    }
  }
}

.breadcrumb-item-home::before {
  float: left; // Suppress inline spacings and underlining of the separator
  padding-right: $breadcrumb-item-padding;
  // stylelint-disable-next-line
  font-family: "Material Icons Sharp";
  color: $breadcrumb-divider-color;
  content: "home";
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  // stylelint-disable-next-line
  font-family: "Material Icons Sharp";
  color: $breadcrumb-divider-color;
  content: "chevron_right";
}

// Remove margins/padding on Breadcrumbs for larger displays
@include media-breakpoint-up(lg) {
  .breadcrumb {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
