// Overrides for the Navbar component for off-canvas menu.
.offcanvas-toggle {
  display: none;
}
.offcanvas-toggle-body-freeze {
  overflow-y: hidden;
}
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: .75;
  }
}
// >> .navbar-offcanvas
.menu-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1999;
  background-color: rgb(64, 54, 53);
  opacity: .75;
}
.menu-backdrop.show {
  cursor: pointer;
  animation: .3s linear fadein;
}
.navbar-offcanvas {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  width: 325px;
  max-width: 90%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  background-color: $navbar-offcanvas-bg;
  border: none;
  /* stylelint-disable */
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  transform: translateX(100vw);
  &.open {
    transform: translateX(0); /* Account for horizontal padding on navbar */
  }

  .navbar-offcanvas-header {
    display: block;
    width: 100%;
  }

  .navbar-offcanvas-home {
    font-weight: 400;
    color: $navbar-offcanvas-link-color;
    text-decoration: none;
  }

  .navbar-offcanvas-search {
    .form-control {
      height: auto;
      margin: 0;
      border: none;
    }
    .input-group {
      input.form-control {
        height: 60px;
        padding-left: 20px;
      }
      .input-group-append > button.btn-search {
        height: 60px;
        width: 60px;
        font-size: 24px;
        border: 0;
        padding: 0;
      }
    }
  }

  .navbar-nav {
    width: 100%;
    overflow-y: auto;
    .show > .nav-link {
      color: $navbar-offcanvas-link-color;
      background-color: $navbar-offcanvas-bg-expanded;
      &:hover {
        background-color: $navbar-offcanvas-bg-expanded;
      }
    }
  }

  .nav-item-parent {
    .nav-link {
      text-transform: none;
    }
  }

  .nav-item {

    &.active {
      color: $navbar-offcanvas-link-color;
      background-color: $navbar-offcanvas-link-active-bg;
    }

    &.show {
      .dropdown-toggle,
      .dropdown-toggle:hover,
      .dropdown-toggle:focus {
        background-color: $navbar-offcanvas-bg-expanded;
        cursor: pointer;
      }
      .dropdown-toggle {
        &::after {
          content: "\e15b";
        }
      }
    }

    .nav-link {
      padding: $navbar-offcanvas-link-padding;
      color: $navbar-offcanvas-link-color;
      text-align: left;
      width: 100%;
      min-height: 48px;

      &.disabled {
        font-weight: 400;
        color: $navbar-offcanvas-link-disabled-color;
        &:hover {
          background-color: $navbar-offcanvas-bg !important;
        }
      }

      &:hover,
      &:focus {
        color: $navbar-offcanvas-link-color;
        background-color: $navbar-offcanvas-link-hover-bg;
      }
    }
  }

  .dropdown-menu {
    padding: 0;
    margin: 0;
    background-color: $navbar-offcanvas-bg-expanded;
    border: none;

    .dropdown-item {
      padding: $navbar-offcanvas-dropdown-link-padding;
      white-space: normal;
    }
  }
  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: normal;

    &::after {
      font-size: 1rem;
      vertical-align: unset;
      content: "\e145";
      border: none;
      margin-left: 20px;
      font-family: 'Material Icons Sharp';
    }
  }
  .dropdown-divider {
      margin: 0;
  }
}

.container .navbar-offcanvas.open {
  transform: translateX(0); /* Account for horizontal padding on container */
}

.btn-menu {
  width: 60px;
  height: 50px;
  border: 0;
  padding: 0;
  font-size: 10px;
  background-color: $red;
  &:hover {
    background-color: $chili;
  }
  span.material-icons-sharp {
    display: block;
    font-size: 24px;
    line-height: 24px;
  }
}

.btn-menu-offcanvas-nav {
  width: 60px;
  height: 50px;
  border: 0;
  padding: 0;
  font-size: 10px;
  span.material-icons-sharp {
    display: block;
    font-size: 24px;
    line-height: 24px;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-offcanvas {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 1rem;
    padding: initial;
    overflow-y: initial;
    background-color: initial;
    border-top: 1px solid $navbar-light-border-color;
    border-bottom: 1px solid $navbar-light-border-color;
    -webkit-transform: initial;
    transform: initial;
    top: initial;
    right: initial;
    z-index: initial;
    flex-direction: initial;
    width: initial;
    height: initial;
    transition: initial;
    // Because flex properties aren't inherited, we need to redeclare these first
    // few properties so that content nested within behave properly.
    %container-flex-properties {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
    &.open {
      transform: initial;
    }
    .navbar-offcanvas-header {
      display: none;
    }
    .navbar-nav {
      display: flex;
      flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      width: initial;
      overflow-y: initial;

      .nav-link {
        padding: $nav-link-padding-y $nav-link-padding-x;
        text-decoration: if($link-decoration == none, null, none);

        @include hover-focus() {
          text-decoration: none;
        }

        // Disabled state lightens text
        &.disabled {
          color: $nav-link-disabled-color;
          pointer-events: none;
          cursor: default;
        }
      }
    }

    .nav-item {
      .nav-link {
        padding: 1.25rem 1rem;
        color: $navbar-light-link-color;
        &:hover {
          color: $navbar-light-link-active-color;
          background-color: $navbar-light-link-hover-bg;
        }
      }
      &.active,
      &:active {
        color: $navbar-light-link-active-color;
        background-color: $navbar-light-link-active-bg;
      }
      &:hover {
        color: $navbar-light-link-active-color;
        background-color: $navbar-light-link-hover-bg;
      }
      &.show {
        .dropdown-toggle,
        .dropdown-toggle:hover,
        .dropdown-toggle:focus {
          background-color: $navbar-light-link-hover-bg;
          color: $navbar-light-active-color;
        }
        .dropdown-toggle {
          &::after {
            content: "";
          }
        }
      }
    }

    .dropdown-menu {
      padding: $dropdown-padding-y 0;
      margin: $dropdown-spacer 0 0; // override default ul
      background-color: $dropdown-bg-color;
      border: none;
      border: $dropdown-border-width solid $dropdown-border-color;
      @include border-radius($dropdown-border-radius);
      @include box-shadow($dropdown-box-shadow);
      position: absolute;
      .dropdown-item {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        white-space: nowrap;
      }
    }
    .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::after {
        font-size: inherit;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        margin-left: 0.225em;
        display: inline-table;
      }
    }
  }
  .menu-backdrop {
    display: none;
  }
}
